<template>
  <div style="margin-top: 25px; margin-left: 60px">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item
        :label="$t('AppManage.sdkStrengPage.name') + '：'"
        prop="sdkName"
      >
        <el-input
          size="small"
          v-model="ruleForm.sdkName"
          :placeholder="$t('AppManage.sdkStrengPage.placeholder')"
          style="width: 250px"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('AppManage.sdkStrengPage.sdkVersion') + '：'"
        prop="version"
      >
        <el-input
          size="small"
          v-model="ruleForm.version"
          :placeholder="$t('AppManage.sdkStrengPage.placeholderVersion')"
          style="width: 250px"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('AppManage.sdkStrengPage.SelectFlie') + '：'"
        prop="fileName"
      >
        <el-input
          :disabled="true"
          size="small"
          v-model="ruleForm.fileName"
          :placeholder="$t('AppManage.sdkStrengPage.placeholderSelect')"
          style="width: 250px"
        ></el-input>
        <el-upload
          action="#"
          :auto-upload="false"
          :show-file-list="false"
          :on-success="handleAvatarSuccess1"
          class="el-button mar-class-8"
          style="padding: 0; margin-left: 12px; border: none"
          :on-change="changeUpload"
          multiple
          accept=".zip,.aar"
        >
          <el-button
            size="small"
            style="
              width: 98px;
              position: relative;
              background: #e5981e;
              border: none;
            "
          >
            <span
              style="
                cursor: pointer;
                margin-top: 5px;
                position: absolute;
                top: 1px;
              "
            >
              <img src="@/images/../images/look.png" alt="" />
            </span>
            <span style="padding-left: 18px; color: white">{{
              $t("AppManage.sdkStrengPage.SelectFlie")
            }}</span>
          </el-button>
        </el-upload>
        <el-progress
          v-if="flagStatus"
          :percentage="percentage"
          :color="customColorMethod"
          style="width: 265px"
        ></el-progress>
      </el-form-item>
      <el-form-item>
        <el-button
          size="small"
          :loading="logdingFlag"
          @click="submitForm('ruleForm')"
          type="primary"
          >{{ $t("public.Save") }}</el-button
        >
      </el-form-item>
    </el-form>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import dialogInfo from "@/components/promptMessage/index";
export default {
  components: { dialogInfo },
  data() {
    var validateInput = (rule, value, callback) => {
      if (!this.checkSpecificKey(value)) {
        callback(new Error(this.$t("AppManage.sdkStrengPage.fileNameReg")));
      } else {
        callback();
      }
    };
    // let validateVersion = (rule, value, callback) => {
    //   let regExp = /^[1-9]\d?(\.(0|[1-9]\d?)){1,19}$/;
    //   if (regExp.test(value) === false) {
    //     callback(new Error(this.$t("AppManage.sdkStrengPage.versionReg")));
    //   } else {
    //     callback();
    //   }
    // };
    let validateSDKName = (rule, value, callback) => {
      let regExp = /^[A-Za-z_]{1,30}$/;
      if (regExp.test(value) === false) {
        callback(new Error(this.$t("AppManage.sdkStrengPage.sdkNameReg")));
      } else {
        callback();
      }
    };

    return {
      logdingFlag: false,
      titleInfo: this.$t("public.SuccessfullySaved"),
      visibaelFlag: false, //提示语框
      percentage: 0,
      flagStatus: false, //进度条显示 默认隐藏
      ruleForm: {
        sdkName: "",
        version: "",
        fileName: "",
        path: "",
        size: "",
      },
      rules: {
        sdkName: [
          {
            required: true,
            message: this.$t("AppManage.sdkStrengPage.sdkNameVoid"),
            trigger: "blur",
          },
          { validator: validateSDKName, trigger: "blur" },
        ],
        version: [
          {
            required: true,
            message: this.$t("AppManage.sdkStrengPage.versionVoid"),
            trigger: "blur",
          },
          // { validator: validateVersion, trigger: "blur" },
        ],
        fileName: [
          {
            required: true,
            message: this.$t("AppManage.sdkStrengPage.fileNameVoid"),
            trigger: "blur",
          },
          { validator: validateInput, trigger: ["blur", "change"] },
        ],
      },
    };
  },
  methods: {
    checkSpecificKey(str) {
      var specialKey =
        "[`~!#$^&*()=|{}':;',\\[\\]<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'";
      for (var i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false;
        }
      }
      return true;
    },
    mathdom() {
      var num = Math.floor(Math.random() * 10 + 1);
      return num;
    },
    customColorMethod(percentage) {
      if (percentage < 30) {
        return "#909399";
      } else if (percentage < 70) {
        return "#e6a23c";
      } else {
        return "#67c23a";
      }
    },
    handleClose() {
      this.visibaelFlag = false;
    },
    //文件上传接口
    async changeUpload(file) {
      console.log(file, "文件上传接口");
      this.percentage = 0;
      this.flagStatus = true;
      if (file.size / (1024 * 1024) > 500) {
        // 限制文件大小
        this.$message({
          message: this.$t("AppManage.sdkStrengPage.LimitFileSize"),
          type: "error",
          offset: 100,
        });
        return false;
      }
      let fileName = file.name;
      let suffix = file.name?.substring(fileName.lastIndexOf(".") + 1); //获取文件后缀名
      let suffixArray = ["zip", "aar"]; //限制的文件类型，根据情况自己定义
      if (suffixArray.indexOf(suffix) === -1) {
        this.$message({
          message: this.$t("AppManage.sdkStrengPage.FileFormatError"),
          type: "error",
          offset: 100,
        });
      } else {
        if (file.size / (1024 * 1024) > 70) {
          //大于100m
          let timer = setInterval(() => {
            this.percentage += this.mathdom();
            if (this.percentage >= 90) {
              this.percentage = 95;
              clearInterval(timer);
            }
          }, 1500);
        }
        if (file.size / (1024 * 1024) < 70) {
          //判断文件小于50m
          let timer = setInterval(() => {
            this.percentage += this.mathdom();
            if (this.percentage >= 90) {
              this.percentage = 95;
              clearInterval(timer);
            }
          }, 100);
        }
        const form = new FormData();
        // 文件对象
        form.append("files", file.raw);
        form.append("type", "protected");
        form.append("fileType", suffix);
        form.append("moduleName", "sdk");
        const res = await this.$axios.upload(
          "/httpServe/sysFile/fileUp",
          form,
          this.updateProgress,
          true
        );
        console.log(res, "SDK文件上传");

        this.ruleForm.path = res.data.data[0];
        this.ruleForm.fileName = file.name;
        this.ruleForm.size = file.size;
        if (res.code == 500) {
          this.flagStatus = false;
        }
        if (this.ruleForm.path) {
          this.$message({
            message: this.$t("AppManage.sdkStrengPage.FileUpSuccessfully"),
            type: "success",
            offset: 100,
          });
          this.flagStatus = false;
        } else {
          this.$message({
            message: this.$t("AppManage.sdkStrengPage.FileUploadFailed"),
            type: "error",
            offset: 100,
          });
          this.flagStatus = false;
        }
      }
    },
    updateProgress(e) {
      //e为回调回来的参数 通过进行和total的值来进行进度
    },
    handleAvatarSuccess1() {},
    //保存按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveSubmit();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //保存
    async saveSubmit() {
      this.logdingFlag = true;
      var params = {
        sdkName: this.ruleForm.sdkName,
        sdkVersion: this.ruleForm.version,
        sdkFile: this.ruleForm.fileName,
        sdkSize: this.ruleForm.size,
        filePath: this.ruleForm.path,
      };
      console.log(params, "保存params");
      const res = await this.$axios.post(
        "/httpServe/sdkReinforce/save",
        params,
        true
      );
      console.log(res, "res");
      // .then(() => {
      if (res.data == 1) {
        this.ruleForm.sdkName = "";
        this.ruleForm.version = "";
        this.ruleForm.fileName = "";
        this.logdingFlag = false;
        this.$emit("goBack");
      }
      if (res.data == -1) {
        this.logdingFlag = false;

        this.ruleForm = {};
        this.$message({
          message: this.$t("AppManage.sdkStrengPage.fileAlreadyExists"),
          type: "error",
          offset: 100,
        });
      }
      if (res.code == 500) {
        this.logdingFlag = false;
        this.ruleForm = {};
        this.$message({
          message: this.$t("AppManage.sdkStrengPage.SaveFailed"),
          type: "success",
          offset: 100,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
</style>