<template>
  <div>
    <!-- sdk加固 -->
    <navi-gation />
    <div class="sdkStrength" v-if="show_up == 0">
      <el-row :gutter="4" class="mt15" style="display: flex">
        <el-col :span="3">
          <el-input
            v-model="form.name"
            size="small"
            :placeholder="$t('AppManage.sdkStrengPage.placeholder')"
            @keyup.enter.native="onSubmit"
          ></el-input>
        </el-col>
        <el-col :span="4">
          <el-button size="small" type="primary" v-debounce:500="onSubmit">{{
            $t("public.Inquire")
          }}</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="0" class="mt15" style="padding: 10px 0px; display: flex">
        <el-col :span="1.5">
          <el-button
            class="fileUp"
            @click="fileUp"
            type="primary"
            size="small"
            >{{ $t("AppManage.sdkStrengPage.UploadFiles") }}</el-button
          >
        </el-col>
        <el-col :span="24">
          <el-button-group>
            <el-button
              style="
                border-radius: 0px !important;
                border-left: 1px solid #e4e4e6 !important;
                border-right: 1px solid #e4e4e6 !important;
              "
              @click="reinforce"
              :disabled="delFlag"
              :type="typeInfo"
              size="small"
              >{{ $t("AppManage.sdkStrengPage.SDKReinforcement") }}</el-button
            >
            <el-button
              :disabled="hasSeleted || deletesBtn"
              :type="typeInfo2"
              size="small"
              @click="delMuch(delArr)"
              >{{ $t("public.Delete") }}</el-button
            >
          </el-button-group>
        </el-col>
      </el-row>
      <el-row class="formBox">
        <el-table
          stripe
          ref="multipleTable"
          :row-key="getRowKeys"
          :data="tableData"
          size="mini"
          @select-all="handleAll"
          @select="selectOne"
          @selection-change="handleSelectionChange"
          @sort-change="sort"
          :default-sort="{ prop: 'createTime', order: 'descending' }"
          style="width: 100%"
          :row-style="{ height: '35px' }"
          :cell-style="{ padding: '3px 0px' }"
          :header-row-style="{ height: '35px' }"
          :header-cell-style="{
            background: '#f7f8fa',
            color: '#606266',
            padding: '0px',
          }"
          class="tableClass"
        >
          <el-table-column
            type="selection"
            align="center"
            width="60"
            :selectable="selectEnable"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column
            prop="sdkName"
            :label="$t('AppManage.sdkStrengPage.name')"
            width="100"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="sdkFile"
            :label="$t('AppManage.sdkStrengPage.fileName')"
            width="100"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="sdkVersion"
            :label="$t('public.Version')"
            width="100"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="fileSize"
            :label="$t('AppManage.sdkStrengPage.size')"
            width="100"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              {{ $common.formatFlowSize(row.fileSize) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="purgeStatus"
            :label="$t('AppManage.sdkStrengPage.ReinforcementStatus')"
            width="100"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <span v-if="row.purgeStatus == 0">
                {{ $t("AppManage.sdkStrengPage.Unreinforced") }}</span
              >
              <span v-else-if="row.purgeStatus == 1">
                {{ $t("AppManage.sdkStrengPage.Reinforced") }}</span
              >
              <span v-else-if="row.purgeStatus == 2">
                {{ $t("AppManage.sdkStrengPage.Strengthening") }}</span
              >
              <span v-else-if="row.purgeStatus == 3">
                {{ $t("AppManage.sdkStrengPage.ReinforcementFailure") }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="groupFullName"
            :label="$t('public.Section')"
            width="100"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <span v-if="row.groupFullName">
                {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            :label="$t('AppManage.sdkStrengPage.uploaded')"
            width="180"
            sortable="custom"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="sdkName"
            style="line-height: 5px"
            :label="$t('public.Controls')"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <span
                style="padding: 0px"
                circle
                class="action_icon"
                v-if="row.purgeStatus !== 2"
                @click="fasten(row)"
              >
                <img
                  src="@/images/icon_fasten.png"
                  :title="$t('AppManage.sdkStrengPage.reinforce')"
                  alt=""
                />
              </span>
              <span
                style="padding: 0px"
                circle
                class="action_icon"
                v-if="row.purgeStatus !== 2"
                @click="downLoad(row)"
              >
                <img
                  src="@/images/icon_download.png"
                  :title="$t('public.Download')"
                  alt=""
                />
              </span>

              <span style="padding: 0px" circle class="action_icon"
                ><img
                  src="@/images/icon_del.png"
                  alt=""
                  :title="$t('public.Delete')"
                  @click="del(row)"
              /></span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <!-- 分页-->
      <pagination
        style="margin-right: 10px"
        :page="page.currentPage"
        :limit="page.pageSize"
        :total="page.total"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetList"
      ></pagination>
    </div>
    <!-- 上传文件 -->
    <child-up v-if="show_up == 1" @goBack="go_back"></child-up>
    <!-- SDK加固功能 -->
    <SDKStrong
      v-if="show_up == 2"
      :reinforceData="reinforceData"
      @go="goToList"
    />
    <!-- 提示语弹框 -->
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="sendMsg"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
    <!-- 下载文件弹框 -->
    <Eldialog
      :width="'450px'"
      @handleClose="handleClose"
      :title="$t('AppManage.sdkStrengPage.DownloadFiles')"
      :dialogVisible="downloadVisible"
    >
      <div
        style="display: flex; justify-content: space-around; margin-top: 50px"
      >
        <el-button type="primary" @click="PrimaryFile">{{
          $t("AppManage.sdkStrengPage.OriginalFile")
        }}</el-button>
        <el-button type="primary" @click="StrengtheningFile">{{
          $t("AppManage.sdkStrengPage.ReinforcementFile")
        }}</el-button>
      </div>
    </Eldialog>
  </div>
</template>
<script>
import Eldialog from "@/components/elDialog";
import childUp from "./components/childUp.vue";
import SDKStrong from "./components/sdkStrong.vue";
import naviGation from "@/components/hearder/index";
import pagination from "@/components/pagination/page.vue";
import dialogInfo from "@/components/promptMessage/index";
export default {
  components: {
    childUp,
    Eldialog,
    SDKStrong,
    naviGation,
    pagination,
    dialogInfo,
  },
  data() {
    return {
      form: {
        name: "",
      },
      page: {
        total: 0, //返回数据总条数
        pageSize: 10, //每页显示条数
        orderRule: "DESC", //排序规则ASC、DESC
        currentPage: 1, //当前页码
        orderColume: "createTime", //排序字段
        searchFileName: "", //查询
        groupFullNameShowByIndex: "",
      },
      delArr: [], //存放要删除的数据
      rowObj: {}, //复选框勾选一条数据
      show_up: 0,
      delFlag: true, //是否有勾选数据
      button1: "info",
      button2: "info",
      typeInfo: "info",
      filePath: "", //原始文件存储地址
      delFlag2: true, //是否有勾选数据
      typeInfo2: "info",
      tableData: [],
      titleInfo: this.$t("public.SuccessfullySaved"),
      disabled1: true,
      disabled2: true,
      hasSeleted: true,
      deletesBtn: true,
      visibaelFlag: false, //提示语框
      reinforceData: {},
      filePath_safe: "", //已加固文件
      downloadVisible: false, //下载文件弹框
    };
  },
  created() {},
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.getList();
  },
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page;
      this.page.pageSize = limit;
      //调用列表方法
      this.getList();
    },

    //排序
    sort(column) {
      if (column.order === "ascending") {
        this.page.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.page.orderRule = "DESC";
      }
      this.page.orderColume = column.prop;
      //调用列表方法
      this.getList();
    },
    //获取列表展示
    async getList() {
      var params = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        sdkName: this.form.name,
      };
      const res = await this.$axios.post(
        "/httpServe/sdkReinforce/getDataInfo",
        params,
        true
      );

      this.tableData = res.data.content;
      this.page.total = res.data.total;
    },
    //查询
    async onSubmit() {
      this.page.currentPage = 1;
      var params = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        sdkName: this.form.name,
      };
      const res = await this.$axios.post(
        "/httpServe/sdkReinforce/getDataInfo",
        params,
        true
      );

      this.tableData = res.data.content;
      this.page.total = res.data.total;
    },
    // //表格全选会触发该事件
    handleAll(obj) {
      if (obj.length > 0) {
        this.delArr = [...obj];
        this.hasSeleted = false;
        this.deletesBtn = false;
        this.typeInfo2 = "primary";
      } else {
        this.hasSeleted = true;
        this.deletesBtn = true;
        this.typeInfo2 = "info";
      }
    },
    handleSelectionChange(val) {
      this.delArr = [...val];
      if (val.length == 0) {
        this.hasSeleted = true;
        this.deletesBtn = true;
        this.delFlag = true;
        this.typeInfo2 = "info";
        this.typeInfo = "info";
      } else if (val.length == 1) {
        if (val.purgeStatus == 2) {
          this.delFlag = true;
          this.typeInfo = "info";
        } else {
          this.delFlag = false;
          this.hasSeleted = false;
          this.deletesBtn = false;
          this.typeInfo2 = "primary";
          this.typeInfo = "primary";
        }
      }
      if (this.delArr.length > 1) {
        this.typeInfo = "info";
        this.delFlag = true;
      }
    },
    //仅对 type=selection 的列有效，类型为 Function，Function 的返回值用来决定这一行的 CheckBox 是否可以勾选
    selectEnable(row, rowIndex) {
      if (row.purgeStatus == 2) {
        return false;
      } else {
        return true;
      }
    },
    //全选删除
    // handleAll() {},
    selectOne(selection, row) {
      let selected = selection.length && selection.indexOf(row) !== -1;
      // console.log(selected, 'true就是选中，0或者false是取消选中') // true就是选中，0或者false是取消选中
      this.delArr = selection;
      if (row.purgeStatus != 2) {
        if (selected) {
          var tem = JSON.parse(JSON.stringify(row));
          this.rowObj = tem;
          var arr = eval("(" + tem.soList + ")");
          var arrList = [];
          arr.forEach((item) => {
            const obj = {
              name: item,
            };
            arrList.push(obj);
          });
          this.rowObj.soList = [...arrList];
          this.rowObj.purgeInfo = JSON.parse(tem.purgeInfo);
          this.delFlag = false;
          this.typeInfo = "primary";
        } else {
          this.delFlag = true;
          this.typeInfo = "info";
        }
      }
    },
    //上传文件
    fileUp() {
      this.show_up = 1;
    },
    //返回列表页面
    go_back() {
      this.show_up = 0;
      this.$message({
        message: this.$t("public.SuccessfullySaved"),
        type: "success",
        offset: 100,
      });
      this.form.name = "";
      this.getList();
    },
    goToList() {
      this.show_up = 0;
      this.delFlag = true;
      this.typeInfo = "info";

      this.hasSeleted = true;
      this.deletesBtn = true;
      this.typeInfo2 = "info";
      this.form.name = "";
      this.getList();
    },
    // 加固功能
    reinforce() {
      console.log(this.rowObj, "加固功能");
      if (this.rowObj.purgeStatus != 1) {
        this.getBeforeFasten(this.rowObj);
      } else {
        this.reinforceData = this.rowObj;
        this.show_up = 2;
      }
    },
    // 加固
    fasten(val) {
      if (val.purgeStatus != 1 && val.purgeStatus != 2) {
        this.getBeforeFasten(val);
      } else {
        if (val.soList.indexOf("[") != -1) {
          var arr = eval("(" + val.soList + ")");
          var arrList = [];
          arr.forEach((item) => {
            const obj = {
              name: item,
            };
            arrList.push(obj);
          });
          val.soList = [...arrList];
          val.purgeInfo = JSON.parse(val.purgeInfo);
          console.log(val, "val324");
          this.reinforceData = val;
          this.show_up = 2;
        } else {
          val.soList = [];
          val.purgeInfo = JSON.parse(val.purgeInfo);
          console.log(val, "val330");
          this.reinforceData = val;
          this.show_up = 2;
        }
      }
    },
    //获取上个版本的加固策略记录
    async getBeforeFasten(val) {
      var params = {
        sdkName: val.sdkName,
      };
      const res = await this.$axios.post(
        "/httpServe/sdkReinforce/getConfig",
        params,
        true
      );
      val.purgeInfo = JSON.parse(res.data);
      val.sdkName = val.sdkName;
      val.sdkFile = val.sdkFile;
      val.sdkVersion = val.sdkVersion;
      console.log(val, "新的加固策略");
      if (val.soList.indexOf("[") != -1) {
        var arr = eval("(" + val.soList + ")");
        var arrList = [];
        arr.forEach((item) => {
          const obj = {
            name: item,
          };
          arrList.push(obj);
        });
        val.soList = [...arrList];
        this.reinforceData = val;
        this.show_up = 2;
      } else if (Array.isArray(val.soList)) {
        this.reinforceData = val;
        this.show_up = 2;
      } else {
        val.soList = [];
        console.log(val, "val330");
        this.reinforceData = val;
        this.show_up = 2;
      }
    },
    //删除单项
    async del(v) {
      var params = {
        ids: [v.id],
      };
      this.$confirm(
        this.$t("AppManage.sdkStrengPage.sureDeleteSeleSDKFile"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/sdkReinforce/delete",
            params,
            true
          );
          if (res.data == 1) {
            this.$message({
              message: this.$t("public.SuccessfullyDeleted"),
              type: "success",
              offset: 100,
            });
            this.page.currentPage = 1;
            this.$refs.multipleTable.clearSelection();
            this.getList();
          } else if (res.data == 0) {
            this.$message({
              message: this.$t("AppManage.sdkStrengPage.SaveFailed"),
              type: "success",
              offset: 100,
            });
            this.page.currentPage = 1;
            this.getList();
          }
        })
        .catch(() => {
          return false;
        });
    },

    async delMuch(obj) {
      var ids = [];
      obj.forEach((item) => {
        ids.push(item.id);
      });
      var params = {
        ids: ids,
      };
      this.$confirm(
        this.$t("AppManage.sdkStrengPage.sureDeleteSeleSDKFile"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          const res = await this.$axios
            .post("/httpServe/sdkReinforce/delete", params, true)
            .then(async () => {
              this.$message({
                message: this.$t("public.SuccessfullyDeleted"),
                type: "success",
                offset: 100,
              });
              this.page.currentPage = 1;
              this.$refs.multipleTable.clearSelection();
              this.getList();
            })
            .catch(() => {
              this.$message({
                message: this.$t("AppManage.sdkStrengPage.SaveFailed"),
                type: "error",
                offset: 100,
              });
            });
        })
        .catch(() => {
          return false;
        });
    },
    //下载
    downLoad(row) {
      console.log(row, "下载row");
      this.filePath = row.filePath;
      //已加固
      if (row.purgeStatus == 1) {
        const str = row.filePath;
        const strCopy = str.split("/");
        strCopy[strCopy.length - 1] = "safe-" + strCopy[strCopy.length - 1];
        this.filePath_safe = strCopy.join("/");
        //弹框出现
        this.downloadVisible = true;
        //未加固
      } else if (row.purgeStatus != 1) {
        //直接下载原始文件
        this.PrimaryFile();
      }
    },
    //下载原始文件
    async PrimaryFile() {
      var params = {
        type: "protected",
        path: this.filePath,
      };
      console.log(params, "原始文件参数");
      const res = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        params,
        true
      );
      console.log(res, "下载原始文件");
      if (res.data == null) {
        this.$message({
          message: this.$t("AppManage.sdkStrengPage.OriginalFileEmpty"),
          type: "error",
          offset: 100,
        });
        return false;
      } else {
        await this.$axios.downloadFile(res.data);
      }
      this.downloadVisible = false; //关闭选择下载弹框
    },
    //下载加固文件
    async StrengtheningFile() {
      var params = {
        type: "protected",
        path: this.filePath_safe,
      };
      const res = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        params,
        true
      );
      if (res.data == null) {
        this.$message({
          message: this.$t("AppManage.sdkStrengPage.ReinforcementFileEmpty"),
          type: "error",
          offset: 100,
        });
        return false;
      } else {
        await this.$axios.downloadFile(res.data);
      }

      this.downloadVisible = false; //关闭选择下载弹框
    },
    //下载文件弹框
    handleClose() {
      this.downloadVisible = false;
    },
    //提示语弹框
    sendMsg() {
      this.visibaelFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
.sdkStrength {
  padding: 10px 10px 0px 10px;
  ::v-deep .el-button-group .el-button--primary:last-child {
    border-left-color: rgba(255, 255, 255, 0.8) !important;
  }
  //表格操作图片
  .action_icon {
    border: 0px !important;
    background: rgba(255, 255, 255, 0.1);
    img {
      margin-bottom: -4px !important;
    }
  }
}
.fileUp {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style>
