<template>
  <div style="margin-top:25px;">
    <!-- SDK加固功能 -->
    <el-row :gutter="16">
      <el-col :span="24">
        <el-card shadow="never" style="width: 1000px;">
          <span style="margin-left: 50px" class="fileName1" :title="reinforceData.sdkName">{{ $t("AppManage.sdkStrengPage.name")+'：' }}{{ reinforceData.sdkName }}</span>
          <span style="margin-left: 30px" class="fileName2" :title="reinforceData.sdkFile">{{ $t("AppManage.sdkStrengPage.fileName")+'：' }}{{ reinforceData.sdkFile
          }}</span>
          <span style="margin-left: 30px" class="fileName3" :title="reinforceData.sdkVersion">{{ $t("public.Version")+'：' }}{{
              reinforceData.sdkVersion
          }}</span>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="16" style="padding: 0 60px">
      <el-col :span="24">
        <el-checkbox v-model="basicObfuscationUse">{{ $t("AppManage.sdkStrengPage.JarPackageConfusion") }}</el-checkbox>
      </el-col>
    </el-row>
    <el-row style="padding: 0 80px" :gutter="16" v-if="basicObfuscationUse == true">
      <el-col :span="24">
        <el-checkbox v-model="BasicObfuscation.LineNumberRemover">{{ $t("AppManage.sdkStrengPage.LineNumberRemoval") }}</el-checkbox>
      </el-col>
      <el-col :span="24">
        <el-checkbox v-model="BasicObfuscation.StringEncryption">{{ $t("AppManage.sdkStrengPage.stringEncry") }}</el-checkbox>
      </el-col>
      <el-col :span="24">
        <el-checkbox v-model="BasicObfuscation.NumberObfuscation">{{ $t("AppManage.sdkStrengPage.NumConfusion") }}</el-checkbox>
      </el-col>
      <el-col :span="24">
        <el-checkbox v-model="BasicObfuscation.FlowObfuscator">{{ $t("AppManage.sdkStrengPage.ControlFlowConfusion") }}</el-checkbox>
      </el-col>
    </el-row>
    <el-row style="padding: 0 60px" :gutter="16">
      <el-col :span="24">
        <el-checkbox v-model="soUse">{{ $t("AppManage.sdkStrengPage.soFileReinfo") }}</el-checkbox>
      </el-col>
      <el-col :span="24">
        <el-table class="customer-table" ref="soTable" :data="reinforceData.soList" tooltip-effect="dark" :row-style="iRowStyle" :cell-style="iCellStyle" :header-row-style="iHeaderRowStyle" :header-cell-style="{ background: '#f7f8fa', color: '#606266', padding: '0px' }" style="width: 1000px;height: 152px; overflow: auto" @selection-change="handleSelectionChange1">
          <el-table-column type="selection" width="55" align="center" :selectable="selectEnable"> </el-table-column>
          <el-table-column :label="$t('AppManage.sdkStrengPage.fileTitle')" prop="name"></el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row :gutter="16" style="margin-bottom: 100px">
      <el-col :span="24">
        <el-button type="primary" @click="reinforce" :loading="loading">{{ $t("AppManage.sdkStrengPage.ImmediateReinforcement") }}</el-button>
      </el-col>
    </el-row>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>
<script>
import dialogInfo from '@/components/promptMessage/index'
export default {
  components: { dialogInfo },

  props: {
    reinforceData: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      loading: false,
      visibaelFlag: false,
      titleInfo: '',
      soList: [],
      soData: [],
      soUse: false,
      basicObfuscationUse: false,
      BasicObfuscation: {
        LineNumberRemover: false,
        StringEncryption: false,
        NumberObfuscation: false,
        FlowObfuscator: false
      },
      List: [],
      multipleSelection: []
    }
  },

  computed: {},

  watch: {
    'reinforceData.soList': {
      handler(newVal) {
        console.log(newVal, '监听表格')
        newVal.forEach((item, index) => {
          this.reinforceData.purgeInfo.so.forEach((newItem) => {
            if (item.name == newItem) {
              this.$nextTick(() => {
                //newVal是新的表格数据，[8] 8是表格数据要默认选中的索引
                this.$refs.soTable.toggleRowSelection(newVal[index], true)
              })
            }
          })
        })
      },
      immediate: true
    }
  },

  created() {
    if (this.reinforceData.purgeInfo.soUse == true) {
      this.soUse = true
    }
    if (this.reinforceData.purgeInfo.basicObfuscationUse == true) {
      this.basicObfuscationUse = true
      this.BasicObfuscation.LineNumberRemover =
        this.reinforceData.purgeInfo.BasicObfuscation.LineNumberRemover
      this.BasicObfuscation.StringEncryption =
        this.reinforceData.purgeInfo.BasicObfuscation.StringEncryption
      this.BasicObfuscation.NumberObfuscation =
        this.reinforceData.purgeInfo.BasicObfuscation.NumberObfuscation
      this.BasicObfuscation.FlowObfuscator =
        this.reinforceData.purgeInfo.BasicObfuscation.FlowObfuscator
    }
    // }
  },

  mounted() {},

  methods: {
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'padding:0px'
    },
    // so选中文件
    handleSelectionChange1(val) {
      this.multipleSelection = val
      let data = []
      this.multipleSelection.forEach((item) => {
        data.push(item.name)
      })
      this.soData = data
    },
    // 立即加固
    async reinforce() {
      if (this.basicObfuscationUse == false && this.soUse == false) {
        this.$message({
          message: this.$t('AppManage.sdkStrengPage.SelectAnItem'),
          type: 'error',
          offset: 100
        })
        return
      }
      if (this.basicObfuscationUse == true) {
        if (
          this.BasicObfuscation.FlowObfuscator == false &&
          this.BasicObfuscation.LineNumberRemover == false &&
          this.BasicObfuscation.NumberObfuscation == false &&
          this.BasicObfuscation.StringEncryption == false
        ) {
          this.$message({
            message: this.$t('AppManage.sdkStrengPage.selectJarPackage'),
            type: 'error',
            offset: 100
          })
          return
        }
      }
      if (this.soUse == true) {
        if (this.soData.length <= 0) {
          this.$message({
            message: this.$t('AppManage.sdkStrengPage.selectSoFile'),
            type: 'error',
            offset: 100
          })
          return
        }
      }

      this.$message({
        message: this.$t('AppManage.sdkStrengPage.StrengtheningPleaseWait'),
        type: 'success',
        offset: 100
      })
      this.loading = true

      const params = {
        id: this.reinforceData.id,
        purgeInfo: {
          soUse: this.soUse,
          basicObfuscationUse: this.basicObfuscationUse,
          BasicObfuscation: this.BasicObfuscation,
          so: this.soData
        }
      }
      setTimeout(() => {
        this.$emit('go')
      }, 6000)

      params.purgeInfo = JSON.stringify(params.purgeInfo)

      const res = await this.$axios.post(
        '/httpServe/sdkReinforce/reinforce',
        params,
        true
      )
      console.log(res, '立即加固')
      if (res.code == 200) {
        this.loading = false
      }
    },
    // so文件
    selectEnable() {
      if (!this.soUse) {
        return false
      } else {
        return true // 不禁用
      }
    },
    handleClose() {
      this.visibaelFlag = false
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep .el-row {
  margin-left: 350px !important;
  margin-top: 5px !important;
}

::v-deep .el-col-24 {
  margin: 0px 0 10px 0;
}

::v-deep .el-table .el-table__cell {
  padding: 0;
}

.fileName1 {
  width: 20%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  cursor: pointer;
}

.fileName2 {
  width: 35%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.fileName3 {
  width: 20%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
</style>
